import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';

const Contact = () => {
  return (
    <div>
      <h1>
        <Fade bottom cascade>
          {' '}
          Contact
        </Fade>
      </h1>
      <Fade bottom>
        <div className="contact-content uxcam-occlude">
          <h1>
            Want to talk about <br />
            <span className="amazing-color">creating something?</span>
          </h1>
          <a
            href={`mailto:${data.contactEmail}`}
            className="email uxcam-occlude"
          >
            {data.contactEmail}
          </a>
          <ul className="contact-list">
            {data.social.map((link, index) => (
              <li key={index} className="contact-list__item">
                <a target="_blank" rel="noopener noreferrer" href={link.url}>
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Fade>
      <br />
      <br />
    </div>
  );
};

export default Contact;
