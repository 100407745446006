/* eslint-disable import/no-anonymous-default-export */
export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template
  name: '',
  headerTagline: [
    //Line 1 For Header
    'Hello there, I am Gabriel Madera Castell.',
    //Line 2 For Header
    ' A Full Stack Developer',
    //Line 3 For Header
    'from Madrid',
  ],
  //Contact Email
  contactEmail: 'hello@gabmadera.com',
  // Add Your About Text Here
  abouttext:
    'As a Full Stack Web Developer with a strong background in Front-end development and hands-on experience in mobile app development, I have honed my skills in delivering end-to-end solutions for complex web and mobile applications.',
  abouttext2:
    'Although my educational background is in Chemical Engineering, my passion for coding led me to pursue a career in web development, where I have gained extensive experience in both Front-end and Back-end stacks.',
  abouttext3:
    'Here on this website, you can explore a selection of my projects, which showcase my abilities in both front-end and back-end stacks.',
  aboutImage:
    // 'https://images.unsplash.com/photo-1521587765099-8835e7201186?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
    'https://res.cloudinary.com/dzhsqjfb7/image/upload/v1602339953/ProfilePicture_a2o0gd.jpg',
  //Change This To Hide The Image of About Section (True Or False)
  ShowAboutImage: true, //true or false (Change Here)
  // Change Projects Here
  projects: [
    {
      id: 1, //DO NOT CHANGE THIS (Please)😅
      title: 'Todo List', //Project Title - Add Your Project Title Here
      service: 'Web App with React', // Add Your Service Type Here
      desc: 'A simple To-Do List, where users can manage in a very simple view their pending, favorite and completed tasks.',
      //Project Image - Add Your Project Image Here
      imageSrc:
        'https://images.unsplash.com/photo-1587042167052-00701238fb17?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
      //Project URL - Add Your Project Url Here
      url: 'https://completionist-todo.netlify.app/',
    },
    {
      id: 2, //DO NOT CHANGE THIS (Please)😅
      title: 'Game Hub',
      service: 'SPA with Redux Toolkit',
      desc: 'A simple web app with a Game Hub in mind, three quick and easy to learn games where users can spend a few minutes enjoying any of theese games.',

      imageSrc:
        'https://images.unsplash.com/photo-1531525645387-7f14be1bdbbd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
      url: 'https://gitlab.com/users/gabmadera/starred',
    },
    {
      id: 3, //DO NOT CHANGE THIS (Please)😅
      title: 'Ecommerce API',
      service: 'NodeJS / Express marketplace',
      desc: 'For those who want to take a more technical view to how a basic e-commerce marketplace works.',

      imageSrc:
        'https://images.unsplash.com/photo-1509395062183-67c5ad6faff9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
      url: 'https://gitlab.com/users/gabmadera/starred',
    },
    {
      id: 4, //DO NOT CHANGE THIS (Please)😅
      title: 'MyPet',
      service: 'Pet Services App',
      desc: 'A web app with booking system and online community, users can book caretakers or trainers for their pets, filtering them by proximity. Users can also become caretakers and offer their services trough the "Caretaker Area".',

      imageSrc:
        'https://images.unsplash.com/photo-1497993950456-cdb57afd1cf1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80',
      url: 'https://mypetupgrade.netlify.app/',
    },

    /*

                    If You Want To Add More Project just Copy and Paste This At The End (Update the id Respectively)
                ,{
                id: 5,
                title: 'Project Five',
                service: 'Something Amazing',
                imageSrc: "",
                url: ''
            }
                */
  ],
  social: [
    // Add Or Remove The Link Accordingly
    { name: 'Gitlab', url: 'https://gitlab.com/gabmadera' },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/gabriel-madera-castell/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/gabmadera/',
    },
  ],
};
