/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';
import profilePic from '../images/ProfilePicture.jpg';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <h1>
          <Fade bottom cascade>
            About Me
          </Fade>
        </h1>
        <Fade bottom>
          <p className="uxcam-occlude">{data.abouttext}</p>
          <p>{data.abouttext2}</p>
          <p>{data.abouttext3}</p>
        </Fade>
      </div>
      {data.ShowAboutImage && (
        <img src={profilePic} className="about-image" alt="about image"></img>
      )}
    </div>
  );
};

export default About;
